<template>
  <div class="account" style="padding-bottom: 100px">
    <top-fixed></top-fixed>

    <div style="font-size: 20px; text-align: center; margin-top: 0.6rem">
      {{ $t("hj27") }}
    </div>
    <div
      style="
        margin: 0.7rem 0.6rem;
        box-shadow: 0 0 10px 000;
        background-color: #1d76fb;
        border-radius: 20px;
      "
    >
      <div
        @click="changeLnag('en')"
        style="
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #fefefe;
          height: 1.1rem;
          align-items: center;
          padding: 0 0.4rem;
        "
      >
        <span style="color: #fefefe; font-size: 18px">English</span>
        <img
          style="width: 0.4rem; height: 0.4rem"
          v-if="nowLang !== 'en'"
          src="../assets/img/weixuanzhong.png"
          alt=""
        />
        <img
          style="width: 0.4rem; height: 0.4rem"
          v-else
          src="../assets/img/yixuanzhong.png"
          alt=""
        />
      </div>
      <div
        @click="changeLnag('zh_TW')"
        style="
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #fefefe;
          height: 1.1rem;
          align-items: center;
          padding: 0 0.4rem;
        "
      >
        <span style="color: #fefefe; font-size: 18px">繁體中文</span>
        <img
          style="width: 0.4rem; height: 0.4rem"
          v-if="nowLang != 'zh_TW'"
          src="../assets/img/weixuanzhong.png"
          alt=""
        />
        <img
          style="width: 0.4rem; height: 0.4rem"
          v-else
          src="../assets/img/yixuanzhong.png"
          alt=""
        />
      </div>
    </div>

    <!--background-color: #1d76fb;  -->

    <bottom-fixed></bottom-fixed>
  </div>
</template>
  
  
  <script>
import BottomFixed from "../components/BottomFixed.vue";
import TopFixed from "../components/TopFixed.vue";


export default {
  name: "AccountView",
  data () {
    return {
      index: 0,
      nowLang: '',
    };
  },
  created () {
    this.nowLang = window.localStorage.getItem("locale") ? window.localStorage.getItem("locale") : 'zh_TW'
  },
  components: { BottomFixed, TopFixed },
  methods: {
    changeLnag (value) {
      console.log(value == 'zh-TW', value)
      console.log(this.$i18n.locale, 2)
      window.localStorage.setItem("locale", value)
      this.$i18n.locale = value;
      this.nowLang = value
    },
  },
};
</script>




  